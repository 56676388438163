<template>
  <div class="page">
    <div class="page-content page-create-candidate-manually">
      <div class="page-header">
        <div class="page-header_left">
          <div class="page-header_back-link" @click="$router.back()">
            <div class="icon icon-arrow"></div>
            <span>Назад</span>
          </div>
          <div class="page-title">Заполнить вручную</div>
        </div>
      </div>
      <div class="form-candidates">
        <div v-for="(form, index) in forms" :key="index" class="form-candidate">
          <Preloader v-model="form.preloader" :backdrop="true"/>
          <div
            v-if="index !== 0 || forms.length > 1"
            class="icon icon-close"
            @click="removeForm(form)"
          />
          <v-form class="form" :ref="`form_${index}`">
            <div class="form-candidate_header">
              <div class="main-info">
                <v-text-field
                  v-model="form.data.name"
                  label="Имя"
                  :rules="[v=>!!v || 'Укажите имя']"
                  persistent-placeholder
                />
                <v-text-field
                  :rules="[v=>!!v || 'Укажите фамилию']"
                  v-model="form.data.surname" label="Фамилия"
                  persistent-placeholder
                />
                <v-text-field
                  :rules="[v=>!!v  || 'Укажите номер телефона']"
                  v-model="form.data.phone"
                  v-mask="['+# (###) ###-##-##', '+### (##) ###-##-##']" name="phone"
                  label="Телефон"
                  persistent-placeholder
                />
              </div>
              <div class="form-candidate_photo" @click="addPhotoToForm(form)">
                <img v-if="form.data.image && form.data.imageUrl" :src="form.data.imageUrl" alt="candidate image">
                <template v-else>
                  <div class="icon icon-photos"></div>
                  <span>Фото</span>
                </template>
              </div>
            </div>
            <div v-if="form.data.hasShown" class="form">
              <v-text-field v-model="form.data.position" name="position"
                            label="Должность"
                            persistent-placeholder
              />
              <v-text-field v-model="form.data.email" label="Email" persistent-placeholder/>

              <v-text-field v-model="form.data.contacts[0].value"
                            v-mask="['+# (###) ###-##-##', '+### (##) ###-##-##']"
                            label="Второй телефон"
                            persistent-placeholder
              />
              <v-text-field v-model="form.data.contacts[2].value" label="VK" persistent-placeholder/>
              <v-text-field v-model="form.data.contacts[3].value" label="Facebook" persistent-placeholder/>
              <v-select v-model="form.data.gender" name="gender" label="Пол"
                        item-value="id"
                        item-text="title"
                        append-icon=""
                        :items="[{id: '0', title: 'Не выбран'}, {id: '1', title: 'Мужской'}, {id: '2', title:'Женский'}]"
                        persistent-placeholder
              />
              <v-text-field label="Дата рождения" v-model="form.data.birthday" v-maska="'##.##.####'" persistent-placeholder placeholder="ДД.ММ.ГГГГ"/>
              <AboutList :items="[createListItemResume(form)]"/>
            </div>
          </v-form>

          <div class="toggle-field" @click="form.data.hasShown = !form.data.hasShown">
            <span v-if="form.data.hasShown">Скрыть опции</span>
            <span v-else>Показать все опции</span>
          </div>
        </div>
      </div>
      <div class="add-candidate-button" @click="addForm">
        <div class="icon icon-new"></div>
        Добавить еще кандидата
      </div>
      <!--      <SendCandidates v-if="checkForms" @start="startSubmit" @end="endSubmit" :forms="filledForms">
              <div class="default-button create-candidates-button">
                <span v-if="!saveButtonPreloader">Готово</span>
                <span v-else>&nbsp;</span>
                <Preloader v-model="saveButtonPreloader" :backdrop="true" size="30px" @click.stop/>
              </div>
            </SendCandidates>-->
      <v-btn
        max-width="150"
        depressed
        color="primary"
        @click="createCandidate"
        :loading="loading"
      >Готово
      </v-btn>
    </div>
    <SendCandidates :forms="forms" ref="send-candidates" @start="startSubmit" @end="endSubmit"/>
  </div>
</template>

<script>
import CandidateForm from '@/views/candidates/create/CandidateForm';

import Preloader from '@/components/Preloader.vue';
import AboutList from '@/components/AboutList.vue';
import SendCandidates from '@/views/candidates/create/SendCandidates.vue';

export default {
  name: 'CreateCandidatesManually',
  components: {
    SendCandidates,
    Preloader,
    AboutList,
  },
  data() {
    return {
      forms: [],
      showErrors: false,
      saveButtonPreloader: null,
      dateMenu: false,
      loading: false,
    };
  },
  methods: {
    addForm() {
      this.forms.push(new CandidateForm());
    },
    removeForm(form) {
      this.forms = this.forms.filter((_form) => _form !== form);
    },
    addPhotoToForm(form) {
      const candidate = form;
      const inputFile = document.createElement('input');
      inputFile.setAttribute('type', 'file');
      inputFile.setAttribute('accept', 'image/*');
      inputFile.addEventListener('change', (e) => {
        if (e.target.files.length) {
          candidate.data.image = { url: URL.createObjectURL(e.target.files[0]) };
          candidate.preloader = true;
          this.$server.uploadFile(e.target.files[0], { type: 'candidate' })
            .then((dataResponse) => {
              if (dataResponse.success) {
                candidate.data.imageUrl = dataResponse.response.url;
                candidate.data.image = dataResponse.response.id;
              }
            })
            .finally(() => {
              candidate.preloader = false;
            });
        }
      });
      inputFile.click();
    },
    selectResumeFileToForm(form) {
      const inputFile = document.createElement('input');
      inputFile.setAttribute('type', 'file');
      inputFile.addEventListener('change', (e) => {
        if (e.target.files.length) {
          this.addResumeToForm(form, e.target.files[0]);
        }
      });
      inputFile.click();
    },
    addResumeToForm(form, file) {
      const candidate = form;
      candidate.preloader = true;
      this.$server.uploadFile(file, {
        parse: 1,
        type: 'candidate',
      })
        .then((dataResponse) => {
          if (dataResponse.success) {
            candidate.applyResultParseResume(dataResponse.response);
          }
        })
        .finally(() => {
          candidate.preloader = false;
        });
    },
    createCandidate() {
      for (let index = 0; index < this.forms.length; index++) {
        let form = this.forms[index];
        if (!form.image) form.data.image = null;
        if (!form.resume) form.data.resume = null;
        if (form.data.birthday) form.data.birthday = form.data.birthday.split('.').reverse().join('-');
        if (!this.$refs[`form_${index}`][0].validate()) {
          setTimeout(()=>{
            const el = document.querySelector(".v-input.error--text");
            el.scrollIntoView({behavior: 'smooth'});
          }, 1);
          return;
        }
      }
      this.$refs['send-candidates'].submit();


      /*this.$server.request2('candidate/create', form.data, (data) => {
          this.loading = false;
          if (this.$route.query.v) {
            this.$router.push({path: `/vacancies/vacancy/${this.$route.query.v}`});
            this.$eventBus.emit('create-from-vacancy-add-candidate');
          } else if (index === this.forms.length - 1) this.$router.push('/candidates');
        }, ()=>{
          this.loading = false;
        });*/
    },
    createListItemResume(form) {
      return {
        media: '<div class="icon icon-history"></div>',
        title: 'Резюме',
        emptyValue: 'Добавить',
        value: form.data.resume && form.data.resume.title,
        arrow: true,
        classes: 'pointer',
        onClick: () => {
          this.selectResumeFileToForm(form);
        },
      };
    },
    startSubmit() {
      this.loading = true;
    },
    endSubmit() {
      this.loading = false;
      if (this.$route.query.v) {
        this.$router.push({path: `/vacancies/vacancy/${this.$route.query.v}`});
        this.$eventBus.emit('create-from-vacancy-add-candidate');
      } else this.$router.push('/candidates');
    },
  },
  created() {
    this.addForm();
  },
};
</script>

<style lang="scss" scoped>
.page-create-candidate-manually {
  display: flex;
  flex-direction: column;
}

.form-candidates {
  & > * {
    margin-bottom: 24px;
  }
}

.form-candidate {
  position: relative;
  width: 496px;
  padding: 28px;
  background: #FFFFFF;
  box-shadow: 2px 8px 30px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  overflow: hidden;

  .icon.icon-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 12px;
    font-size: 12px;
    font-weight: bolder;
    cursor: pointer;
    color: rgba(var(--page-color-main-rgb), .25);
  }

  &_header {
    display: flex;
    margin-bottom: 24px;
  }

  .main-info {
    flex-grow: 1;
  }

  .form {
    width: 100%;

    &.hide {
      display: none;
    }
  }

  &_photo {
    width: 80px;
    height: 80px;
    margin-left: 28px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #F4F3F2;
    border-radius: 50%;
    color: rgba(var(--page-color-main-rgb), .25);
    cursor: pointer;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .icon {
      font-size: 35px;
    }

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }

    input {
      display: none;
    }
  }

  .toggle-field {
    margin-top: 28px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: var(--color-blue);
    cursor: pointer;
  }
}

.add-candidate-button {
  margin-bottom: 38px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.25px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .icon {
    font-size: 12px;
    font-weight: bold;
    margin-right: 8px;
  }
}

.create-candidates-button {
  position: relative;
  width: 150px;
}

</style>
